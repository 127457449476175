import React from 'react'
import redback from '../assets/redback.png'
import back from '../assets/back.png'
import head from '../assets/head.png'
import reflect from '../assets/reflect.png'


function Header() {
    return (
        <div className='flex flex-col justify-center items-center mt-5 relative'>


            {/* <img src={reflect} alt="" className='absolute top-0 w-8/12  ' /> */}
            <div className=" flex lg:flex-row flex-row-reverse  lg:justify-between justify-center lg:px-10 px-2 items-center header bg-[#FBE139] rounded-full border-black border-2 lg:w-8/12 w-12/12 h-16 ">
                <div className="one gap-4">
                    <a href="#" className='lg:text-2xl text-xl  lg:mx-3 mx-1'>HOME</a>
                    <a href="#about" className='lg:text-2xl text-xl  lg:mx-3 mx-1'>ABOUT</a>
                    <a href="https://x.com/bejakcoinsol" target="_blank" rel="noopener noreferrer" className='lg:text-2xl text-xl  lg:mx-3 mx-1'>TWITTER</a>
                </div>



                {/* <img src={head} alt="" className='w-14 absolute self-center justify-self-center lg:hidden flex' /> */}
                <img
                    src={redback}
                    alt=""
                    className='lg:w-52 hidden lg:flex transition-transform hover:scale-105 active:scale-95 cursor-pointer mt-14'
                />
            </div>
            <img src={head} alt="" className='w-14 absolute self-center justify-self-center hidden lg:flex' />


        </div>
    )
}

export default Header